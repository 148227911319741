<template>
  <div class="battery-svg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="40"
      viewBox="0 0 120 40"
    >
      <!-- Corpo da bateria -->
      <rect
        x="1"
        y="5"
        width="100"
        height="30"
        rx="5"
        :fill="batteryColor"
        :stroke="batteryColor"
        stroke-width="2"
      />
      <!-- Terminal da bateria -->
      <rect
        x="102"
        y="13"
        width="10"
        height="14"
        rx="2"
        :fill="batteryColor"
        :stroke="batteryColor"
        stroke-width="2"
      />
      <!-- Indicador interno -->
      <rect
        x="5"
        y="9"
        :width="indicatorWidth"
        height="22"
        rx="3"
        fill="#fff"
        :stroke="batteryColor"
      />
      <!-- Texto dinâmico -->
      <text
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
        font-size="14"
        font-family="Arial, sans-serif"
        fill="#4A4A4A"
        font-weight="bold"
        
      >
        {{ batteryValue }} V
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    batteryValue: {
      type: [Number, String],
      default: 12.0, // Valor inicial
    },
    batteryLevel: {
      type: [Number, String],
      default: 100, // Valor inicial
    },
    batteryColor: {
      type: String,
      default: "#28a745", // Cor inicial (verde)
    },
  },
  computed: {
    indicatorWidth() {
      // Calcula a largura do indicador interno baseado no valor da bateria
      const value = parseFloat(this.batteryLevel);
      const maxWidth = 90; // Largura máxima do indicador
      if (value >= 90) return maxWidth; // Bateria cheia
      return value // Proporção do valor
    },
  },
};
</script>

<style scoped>
.battery-svg {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
