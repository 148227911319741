<template>
    <div class=" mt-1">
        <div class="card text-center shadow-sm mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ $t('Battery level') }}</h5>
              <div class="card-text">
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                        <div class="col-6 text-right">
                            
                            <i class="fa fa-microchip fa-2x " :class="getClassStatus(backBattery)" style="font-size: 35px;"></i>
                        </div>
                        <div class="col-6">
                            <BatteryImg 
                            :batteryValue="backBattery > 0? backBattery:'N/A'" 
                            :batteryColor="getClassStatusIcon(backBattery)"
                            :batteryLevel="calcularPorcentagem(backBattery,5.0)"
                             />
                        </div>

                    </div>

                  </div>
                  <div class="col-6">
                    <div class="row">
                        <div class="col-6 text-right">
                            <i class="fa fa-car" :class="getMainClassStatus(mainBattery)" style="font-size: 40px;"></i>
                        </div>
                        <div class="col-6">

                            <BatteryImg 
                            :batteryValue="`${mainBattery > 0? mainBattery: 'N/A'}`" 
                            :batteryColor="`${getMainClassStatusIcon(mainBattery)}`"
                            :batteryLevel="calcularPorcentagem(mainBattery,12.0)"
                             />
                        </div>

                    </div>
                    <p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  </template>
  
  <script>
  import BatteryImg from './BatteryImg';

  export default {
    props: {
      backBattery: {
        type: [Number, String],
        required: true,
        default: 4.0, // Valor inicial para teste
      },
      mainBattery: {
        type: [Number, String],
        required: true,
        default: 12.0, // Valor inicial para teste
      },
    },
    methods: {
        getClassStatus(level) {
            const statusClasses = {
                success: level > 3,
                warning: level > 2 && level <= 3.9,
                danger: level <= 1.9,
            };

            return statusClasses.success ? "text-success" : 
                statusClasses.warning ? "text-warning" : "text-danger";
        },
        getMainClassStatus(level) {
            const statusClasses = {
                success: level > 7,
                warning: level > 4 && level <= 7,
                danger: level <= 4,
            };

            return statusClasses.success ? "text-success" : 
                statusClasses.warning ? "text-warning" : "text-danger";
        },
        getClassStatusIcon(level) {
            const statusClasses = {
                success: level > 3,
                warning: level > 2 && level <= 3.9,
                danger: level <= 1.9,
            };

            return statusClasses.success ? '#28c76f' : 
                statusClasses.warning ? '#ff9f43' : '#ef460e';
        },
        calcularPorcentagem (level, maxLevel) {
            if (maxLevel === 0) {
                0;
            }
            return (level / maxLevel) * 100;
        },
        getMainClassStatusIcon(level) {
            const statusClasses = {
                success: level > 7,
                warning: level > 4 && level <= 7,
                danger: level <= 4,
            };

            return statusClasses.success ? '#28c76f' : 
                statusClasses.warning ? '#ff9f43' : '#ef460e';
        }
    },
    components: {
        BatteryImg,
  },
  };
  </script>
  
  <style scoped>
  .battery-container {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .battery-display {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .battery {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }
  
  .battery-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 30px;
    border: 2px solid #000;
    border-radius: 5px;
    margin-top: 0.5rem;
    font-weight: bold;
    color: #fff;
  }
  
  .battery-green {
    background-color: #28a745;
  }
  
  .battery-yellow {
    background-color: #ffc107;
  }
  
  .battery-red {
    background-color: #dc3545;
  }
  
  .fa {
    font-size: 2rem;
  }
  </style>
  