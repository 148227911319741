<template>
    <div>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
      <div v-if="!error" class="map-container">
        <GmapMap
          ref="gmap"
          :center="currentLocation"
          :zoom="zoom"
          style="width: 100%; height: 500px;"
        >
          <GmapMarker
            v-if="currentLocation"
            :position="currentLocation"
            :icon="markerIcon"
          />
        </GmapMap>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        currentLocation: { lat: -23.55052, lng: -46.633308 }, // São Paulo, Brasil
        zoom: 15, // Zoom inicial do mapa
        error: null, // Mensagem de erro
        markerIcon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Ícone para o marcador
          scaledSize: { width: 40, height: 40 }, // Tamanho do marcador
        },
      };
    },
    methods: {
        getCurrentLocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                this.currentLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                };
                console.log("Localização atual:", this.currentLocation);
            },
            (error) => {
                console.error("Erro ao obter localização:", error);
                this.error = "Não foi possível acessar a localização.";
            }
            );
        } else {
            this.error = "Geolocalização não suportada pelo navegador.";
        }
        }

    },
    mounted() {
      // Solicita a localização quando o componente é montado
      this.getCurrentLocation();
    },
  };
  </script>
  
  <style scoped>
  .map-container {
    width: 100%;
    height: 500px;
    margin: 20px auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  .alert {
    margin: 20px;
  }
  </style>
  