<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <ValidationObserver ref="formObserver" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
                  <div class="form-row">
                    <!-- Center -->
                    <div class="col-md-4">
                      <ValidationProvider name="Center" rules="required" v-slot="{ errors }">
                        <label for="center">
                          <span class="required">*</span> {{ $t('Central') }}:
                        </label>
                        <v-select 
                          id="center"
                          :options="centers" 
                          :reduce="center => center.id" 
                          :label="'name'" 
                          v-model="maintenance.center_id" 
                          @input="changetUnitsCenter" 
                          :class="{ 'is-invalid': errors.length }" />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- Unit -->
                    <div class="col-md-4">
                      <ValidationProvider name="Unit" rules="required" v-slot="{ errors }">
                        <label for="unit">
                          <span class="required">*</span> {{ $t('Unit') }}:
                        </label>
                        <v-select 
                          id="unit"
                          :options="units" 
                          :reduce="unit => unit.id" 
                          :label="'name'" 
                          v-model="maintenance.unit_id" 
                          @input="changeVehicleByUnits" 
                          :class="{ 'is-invalid': errors.length }" />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- Vehicle -->
                    <div class="col-md-4">
                      <ValidationProvider name="Vehicle" rules="required" v-slot="{ errors }">
                        <label for="vehicle">
                          <span class="required">*</span> {{ $t('Vehicle') }}:
                        </label>
                        <v-select 
                          :options="vehicles" 
                          :reduce="vehicle => vehicle.id" 
                          :label="'name'" 
                          v-model="maintenance.vehicle_id" 
                          :class="{ 'is-invalid': errors.length }" />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- Maintenance Company -->
                    <div class="col-md-6">
                      <ValidationProvider name="Maintenance Company" rules="required" v-slot="{ errors }">
                        <label for="maintenance_company">
                          <span class="required">*</span> {{ $t('maintenance_company') }}:
                        </label>
                        <b-form-input 
                          id="maintenance_company" 
                          v-model="maintenance.maintenance_company" 
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>

                    <!-- Maintenance CNPJ -->
                    <div class="col-md-6">
                      <ValidationProvider name="Maintenance CNPJ" rules="required" v-slot="{ errors }">
                        <label for="maintenance_cnpj">
                          <span class="required">*</span> {{ $t('CNPJ') }}:
                        </label>
                        <b-form-input 
                          id="maintenance_cnpj" 
                          v-model="maintenance.maintenance_cnpj" 
                           v-mask="'##.###.###/####-##'"
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>

                    <!-- Maintenance Dates -->
                    <div class="col-md-4">
                      <ValidationProvider name="Start Date" rules="required" v-slot="{ errors }">
                        <label for="start_date">
                          <span class="required">*</span> {{ $t('maintenance_date_start') }}:
                        </label>
                        <b-form-input 
                          type="date" 
                          id="start_date" 
                          v-model="maintenance.maintenance_date_start" 
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider name="End Date" rules="required" v-slot="{ errors }">
                        <label for="end_date">
                          <span class="required">*</span> {{ $t('maintenance_date_end') }}:
                        </label>
                        <b-form-input 
                          type="date" 
                          id="end_date" 
                          v-model="maintenance.maintenance_date_end" 
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4">
                      <ValidationProvider name="Next Maintenance Date" rules="required" v-slot="{ errors }">
                        <label for="next_date">
                          <span class="required">*</span> {{ $t('maintenance_next_date') }}:
                        </label>
                        <b-form-input 
                          type="date" 
                          id="next_date" 
                          v-model="maintenance.maintenance_next_date" 
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>

                    <!-- Value -->
                    <div class="col-md-4">
                      <ValidationProvider name="Value" rules="required" v-slot="{ errors }">
                        <label for="value">
                          <span class="required">*</span> {{ $t('Value') }}:
                        </label>
                        <b-form-input 
                          type="text" 
                          id="value" 
                          v-model="maintenance.value" 
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-8">
                          <ValidationProvider name="Attach files"  v-slot="{ errors }">
                          <div class="form-group">
                            <label for="anexo">
                              <span class="required">*</span> Anexar NFs:
                            </label>
                            <upload-file
                              :fileId="maintenance.fileId"
                              @uploaded="(event) => { maintenance.fileId = event }"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <!-- <span class="invalid-feedback">{{ $t(errors[0]) }}</span> -->
                            </div>
                          </ValidationProvider>
                        </div>

                    <!-- Description -->
                    <div class="col-md-12">
                      <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                        <label for="description">
                          <span class="required">*</span> {{ $t('Description') }}:
                        </label>
                        <b-form-textarea 
                          id="description" 
                          v-model="maintenance.description" 
                          rows="3" 
                          :state="errors.length ? false : null" />
                        <b-form-invalid-feedback>{{ $t(errors[0]) }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>

                    <!-- Requester & Approver -->
                    <div class="col-md-6">
                      <ValidationProvider name="Requester" rules="required" v-slot="{ errors }">
                        <label for="requester">
                          <span class="required">*</span> {{ $t('Requester') }}:
                        </label>
                        <v-select 
                          :options="professionals" 
                          :reduce="professional => professional.id" 
                          :label="'name'" 
                          v-model="maintenance.requester_id" 
                          :class="{ 'is-invalid': errors.length }" />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-6">
                      <ValidationProvider name="Approver" rules="required" v-slot="{ errors }">
                        <label for="approver">
                          <span class="required">*</span> {{ $t('Approver') }}:
                        </label>
                        <v-select 
                          :options="professionals" 
                          :reduce="professional => professional.id" 
                          :label="'name'" 
                          v-model="maintenance.approver_id" 
                          :class="{ 'is-invalid': errors.length }" />
                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <span class="required_fields">
                    <span class="required">*</span>
                    <strong>{{ $t('Required Fields') }}</strong>
                  </span>
                  <ActionButtons backLink="/maintenances" @reset="onReset" />
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from "../../layouts/components/UploadFile";
import { BForm, BFormInput, BFormTextarea, BFormInvalidFeedback } from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ActionButtons from '../../layouts/components/Form/ActionButtons.vue';

extend("required", required);

export default {
  data() {
    return {
      maintenance: {
        status: "1",
        center_id: '',
        unit_id: '',
        vehicle_id: '',
        maintenance_company: '',
        maintenance_cnpj: '',
        maintenance_date_start: '',
        maintenance_date_end: '',
        maintenance_next_date: '',
        value: '',
        description: '',
        requester_id: '',
        approver_id: '',
        fileId:0
      },
      centers: [],
      units: [],
      vehicles: [],
      professionals: []
    };
  },
      methods: {
  
          changetUnitsCenter(center_id) {
              this.maintenance.unit_id = ''
              this.getUnitsCenter(center_id)
          },
          changeVehicleByUnits(unit_id) {
              this.maintenance.vehicle_id = ''
              this.getVehicles(unit_id)
          },
          getUnitsCenter: function (center_id) {
              const self = this;
              const api = `${self.$store.state.api}centers/unit/${center_id}`;
              axios
                  .get(api)
                  .then((response) => {
                      self.units = response.data;
  
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
          onReset:function(){
            this.maintenanc = {
                  status: "1",
                  center_id: '',
                  unit_id: '',
                  vehicle_id: '',
                  maintenance_company:'',
                  maintenance_cnpj:'',
                  maintenance_date_start: '',
                  maintenance_date_end: '',
                  maintenance_next_date: '',
                  value: '',
                  description: '',
                  requester_id: '',
                  approver_id: '',
              }
              this.$refs.formObserver.reset();
              this.$nextTick(() => {
                this.init(); 
              });

          },
          async onSubmit() {
            await this.save();
            this.onReset();
            this.$router.push("/maintenances");
          },
          save: function () {
              const self = this;
              let api = self.$store.state.api + "maintenances";
  
              if (self.maintenance.id) {
                  self.maintenance._method = "PUT";
                  api += "/" + self.maintenance.id;
              }
  
              axios
                  .post(api, self.maintenance)
                  .then((response) => {
                      self.$message(
                         this.$t('Success'),
                          this.$t('Information saved successfully'),
                          "success"
                      );
                      
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
          getMaintenances: function (id) {
              const self = this;
              const api = self.$store.state.api + "maintenances/" + id;
  
              axios
                  .get(api)
                  .then((response) => {
                      self.maintenance = response.data.data[0];
                      self.getUnitsCenter(self.maintenance.center_id);
                      self.getVehicles(self.maintenance.unit_id)
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
          getCenters: function () {
              const self = this;
              const api = self.$store.state.api + "centers/";
  
              axios
                  .get(api)
                  .then((response) => {
                      self.centers = response.data.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
          getVehicles: function (unit_id) {
              const self = this;
              const api = self.$store.state.api + "units/vehicles/" + unit_id;
  
              axios
                  .get(api)
                  .then((response) => {
                      self.vehicles = response.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
          getProfessionals: function () {
              const self = this;
              const api = self.$store.state.api + "professionals/";
  
              axios
                  .get(api)
                  .then((response) => {
                      self.professionals = response.data.data;
                  })
                  .catch((error) => {
                    self.$message(null, self.$t(error.response.data.error), "error");
                  });
          },
  
     
  
      },
      mounted: function () {
          const self = this;
         
          self.getCenters();
          self.getProfessionals();
          let id = self.$route.params.id;
          if (id) {
              self.getMaintenances(id);
          }
      },
      components: {
        ValidationObserver,
        ValidationProvider,
        BForm,
        BFormInput,
        BFormTextarea,
        BFormInvalidFeedback,
        vSelect,
        UploadFile,
        ActionButtons
          },
  };
  </script>
  
  <style scoped>

.required_fields {
  font-weight: bold;
}
  
  </style>
  