var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Center","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"center"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Central'))+": ")]),_c('v-select',{class:{ 'is-invalid': errors.length },attrs:{"id":"center","options":_vm.centers,"reduce":function (center) { return center.id; },"label":'name'},on:{"input":_vm.changetUnitsCenter},model:{value:(_vm.maintenance.center_id),callback:function ($$v) {_vm.$set(_vm.maintenance, "center_id", $$v)},expression:"maintenance.center_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"unit"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Unit'))+": ")]),_c('v-select',{class:{ 'is-invalid': errors.length },attrs:{"id":"unit","options":_vm.units,"reduce":function (unit) { return unit.id; },"label":'name'},on:{"input":_vm.changeVehicleByUnits},model:{value:(_vm.maintenance.unit_id),callback:function ($$v) {_vm.$set(_vm.maintenance, "unit_id", $$v)},expression:"maintenance.unit_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Vehicle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"vehicle"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle'))+": ")]),_c('v-select',{class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicles,"reduce":function (vehicle) { return vehicle.id; },"label":'name'},model:{value:(_vm.maintenance.vehicle_id),callback:function ($$v) {_vm.$set(_vm.maintenance, "vehicle_id", $$v)},expression:"maintenance.vehicle_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Maintenance Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"maintenance_company"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('maintenance_company'))+": ")]),_c('b-form-input',{attrs:{"id":"maintenance_company","state":errors.length ? false : null},model:{value:(_vm.maintenance.maintenance_company),callback:function ($$v) {_vm.$set(_vm.maintenance, "maintenance_company", $$v)},expression:"maintenance.maintenance_company"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Maintenance CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"maintenance_cnpj"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('CNPJ'))+": ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"id":"maintenance_cnpj","state":errors.length ? false : null},model:{value:(_vm.maintenance.maintenance_cnpj),callback:function ($$v) {_vm.$set(_vm.maintenance, "maintenance_cnpj", $$v)},expression:"maintenance.maintenance_cnpj"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"start_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('maintenance_date_start'))+": ")]),_c('b-form-input',{attrs:{"type":"date","id":"start_date","state":errors.length ? false : null},model:{value:(_vm.maintenance.maintenance_date_start),callback:function ($$v) {_vm.$set(_vm.maintenance, "maintenance_date_start", $$v)},expression:"maintenance.maintenance_date_start"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"end_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('maintenance_date_end'))+": ")]),_c('b-form-input',{attrs:{"type":"date","id":"end_date","state":errors.length ? false : null},model:{value:(_vm.maintenance.maintenance_date_end),callback:function ($$v) {_vm.$set(_vm.maintenance, "maintenance_date_end", $$v)},expression:"maintenance.maintenance_date_end"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Next Maintenance Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"next_date"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('maintenance_next_date'))+": ")]),_c('b-form-input',{attrs:{"type":"date","id":"next_date","state":errors.length ? false : null},model:{value:(_vm.maintenance.maintenance_next_date),callback:function ($$v) {_vm.$set(_vm.maintenance, "maintenance_next_date", $$v)},expression:"maintenance.maintenance_next_date"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"value"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Value'))+": ")]),_c('b-form-input',{attrs:{"type":"text","id":"value","state":errors.length ? false : null},model:{value:(_vm.maintenance.value),callback:function ($$v) {_vm.$set(_vm.maintenance, "value", $$v)},expression:"maintenance.value"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Attach files"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"anexo"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" Anexar NFs: ")]),_c('upload-file',{class:{ 'is-invalid': errors.length },attrs:{"fileId":_vm.maintenance.fileId},on:{"uploaded":function (event) { _vm.maintenance.fileId = event }}})],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"description"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Description'))+": ")]),_c('b-form-textarea',{attrs:{"id":"description","rows":"3","state":errors.length ? false : null},model:{value:(_vm.maintenance.description),callback:function ($$v) {_vm.$set(_vm.maintenance, "description", $$v)},expression:"maintenance.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Requester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"requester"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Requester'))+": ")]),_c('v-select',{class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.maintenance.requester_id),callback:function ($$v) {_vm.$set(_vm.maintenance, "requester_id", $$v)},expression:"maintenance.requester_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Approver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"approver"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Approver'))+": ")]),_c('v-select',{class:{ 'is-invalid': errors.length },attrs:{"options":_vm.professionals,"reduce":function (professional) { return professional.id; },"label":'name'},model:{value:(_vm.maintenance.approver_id),callback:function ($$v) {_vm.$set(_vm.maintenance, "approver_id", $$v)},expression:"maintenance.approver_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(_vm._s(_vm.$t('Required Fields')))])]),_c('ActionButtons',{attrs:{"backLink":"/maintenances"},on:{"reset":_vm.onReset}})],1)]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }