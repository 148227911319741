import Crud from '../views/Maintenances/Crud'
import Entity from '../views/Maintenances/Add'

let routes = {
    path: '/maintenances',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/maintenances/add',
            name: 'NewManutencoes',
            component: Entity,
            meta: {
                pageTitle: 'Manutenções',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/Maintenances'
                    },
                    {
                        text: 'Add',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/maintenances/edit/:id?',
            name: 'EditManutencoes',
            component: Entity,
            meta: {
                pageTitle: 'Manutenções',
                breadcrumb: [
                    {
                        text: 'List',
                        active: false,
                        to:'/maintenances'
                    },
                    {
                        text: 'Edit',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/maintenances',
            name: 'list-maintenances',
            component: Crud,
            meta: {
                pageTitle: 'Manutenções',
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
