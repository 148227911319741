<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <!-- Primeira Coluna -->
        <div class="col-3 ">
          <div class="pt-1 pb-1 pl-1">
            <b-avatar :src="vehicleData.vehicle_image_url" size="8rem"></b-avatar>
            <b-avatar
                v-if="vehicleData.deviceActivation.status == 1"
                class="ml-n1 mt-n4"
                :variant="`light-info`"
                size="45"
                >
          <feather-icon size="42" :icon="'RadioIcon'" />
        </b-avatar>
          </div>
              <!-- Botão de download do documento -->

          <div class="col-6 align-content-start-left pt-1">
              <b-button
              v-if="vehicleData.doc_vehicle_url"
              block
              pill
              variant="outline-primary"
              :href="vehicleData.doc_vehicle_url"
              download
              target="_blank"
            >
              {{ $t('CRLV') }} <i class="fa fa-download"></i>
            </b-button>
          </div>
        </div>
        <div class="col-3">
         <p><strong>{{ $t('Central') }} :  </strong>{{ vehicleData.center }}</p>
         <p><strong>{{ $t('Unit') }} : </strong> {{ vehicleData.unit }}</p>
         <p><strong>{{ $t('Name') }} : </strong> {{ vehicleData.vehicle }}</p>
         <p><strong>{{ $t('License Plate') }} :  </strong>{{ vehicleData.license_plate }}</p>
         <p><strong>{{ $t('Acronym') }} : </strong> {{ vehicleData.sigla }}</p>
        </div>

        <!-- Segunda Coluna -->
        <div class="col-3">
         <p><strong> {{ $t('Vehicle Type') }} : </strong>  {{ vehicleData.vehicleType }}</p>
         <p><strong>{{ $t('Specialty') }} : </strong> {{ vehicleData.specialty }}</p>
         <p><strong>{{ $t('Frame') }} : </strong> {{ vehicleData.frame }}</p>
         <p><strong>{{ $t('National car registration') }} :  </strong>{{ vehicleData.national_registry }}</p>
         <p><strong>
            {{ $t('maintenance_next_date') }} : </strong>
            {{ vehicleData.maintenances && vehicleData.maintenances.length
              ? formatDate(vehicleData.maintenances[vehicleData.maintenances.length - 1].maintenance_next_date)
              : dataMoreMonth }}
          </p>
        </div>

        <!-- Terceira Coluna -->
        <div class="col-3">
         <p><strong>{{ $t('Vehicle Brand') }} : </strong> {{ vehicleData.vehicle_brand }}</p>
         <p><strong>{{ $t('Manufacture Year') }} :  </strong>{{ vehicleData.manufacture_year }}</p>
         <p><strong>{{ $t('Model Year') }} :  </strong>{{ vehicleData.model_year }}</p>
         <p><strong>{{ $t('Vehicle Colors') }} : </strong> {{ vehicleData.vehicle_color }}</p>
         <p><strong>{{ $t('Vehicle Model') }} : </strong> {{ vehicleData.vehicle_model }}</p>
        </div>
      </div>
    </div>

   

    <div class="card-footer row">
      <!-- Card de Bateria -->
      <div class="col-12 col-md-4">
        <div class="card text-center shadow-sm mb-4">
          <BatteryComponent :backBattery="getBackBattery"  :mainBattery="getMainBatteryDec" />

        </div>
      </div>

      <!-- Card de Chip -->
      <div class="col-12 col-md-4">
        <div class="card text-center shadow-sm mb-4">
          <div class="card-body">
            <h5 class="card-title mb-3">{{ $t('Chip') }}</h5>
            <p class="card-text mt-1">
              <div class="row">
                <div class="col-6 text-right">
                  <i class="fas fa-satellite  mt-n1 text-primary" style="font-size: 30px;"></i>
                </div>
                <div class="col-6 text-left">

                  <span class="d-block ">{{ vehicleData.deviceActivation.telephone || $t('Without Chip') }}</span>
                </div>

              </div>
              <div class="row mt-2">
                <div class="col-6 text-right">
                  <i class="fas fa-phone  mt-n1 text-primary" style="font-size: 30px;"></i>
                </div>
                <div class="col-6 text-left">

                  <span class="d-block ">{{ vehicleData.telephone_pulsos || $t('No phone') }}</span>
                </div>

              </div>
            </p>
          </div>
        </div>
      </div>

      <!-- Card de Dispositivo -->
      <div class="col-12 col-md-4">
        <div class="card text-center shadow-sm ">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Device') }}</h5>
            <div class="card-text">
              
                <b-button
                class="mr-1 no-border"
                  pill
                  :variant="vehicleData.deviceActivation.status === 1 ? 'success' : 'secondary'"
                  @click="$emit('showDeviceActivations', !modalShow)"
                >
                  <b-badge pill :variant="vehicleData.deviceActivation.status === 1 ? 'success' : 'secondary'">
                    {{ vehicleData.deviceActivation.status === 1 ? $t('Active') : $t('Inactive') }}
                  </b-badge>
                </b-button>
              
                <b-button
                  v-if="vehicleData.deviceActivation.status === 1"
                  class="ml-2 no-border"
                  pill
                  variant="outline-primary"
                  @click="$emit('showDeviceActivationsConfig', !modalConfigShow)"
                >
                  <i class="fa fa-cogs fa-2x"></i>
                </b-button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const HAS_GPS = require('@/assets/images/resgate/avlIcon.png');
import BatteryComponent from './BatteryComponent'

export default {
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
    modalConfigShow: {
      type: Boolean,
      default: false,
    },
    backBattery: {
      type: [Number, String],
      default: 0,
    },
    mainBatteryDec: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      dataMoreMonth: new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString('pt-BR'),
    };
  },
  computed: {
    getBackBattery() {
      return this.backBattery > 10 ? (this.backBattery / 10).toFixed(1) : this.backBattery;
    },
    getMainBatteryDec() {
      return this.mainBatteryDec > 100 ? (this.mainBatteryDec / 10).toFixed(1) : this.mainBatteryDec;
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('pt-BR');
      }
      return '';
    },
    getClassStatus(level) {
      if (level > 3) return 'text-success';
      if (level > 2) return 'text-warning';
      return 'text-danger';
    },
    getMainClassStatus(level) {
      if (level > 7) return 'text-success';
      if (level > 4) return 'text-warning';
      return 'text-danger';
    },
  },
  components: {
    BatteryComponent,
  }
};
</script>

<style scoped>
.no-border {
  border: none !important;
  box-shadow: none !important;
}
</style>
