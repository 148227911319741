
<template>
  <div class="main-content">
    <!-- Formulário de Filtro -->
    <b-form @submit="onSubmit" @reset="onReset" v-if="form">
      <div class="row">
        <!-- Campos de Data e Hora para Filtro -->
        <b-form-group class="col-3" label="Data Inicial:" label-for="form_dataFrist">
          <b-form-datepicker
            id="form_dataFrist"
            v-model="form.dataFrist"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-form-group>
        
        <b-form-group class="col-3" label="Hora Inicial:" label-for="dataFrist_time">
          <b-form-timepicker v-model="form.dataFristTime" show-seconds locale="de" />
        </b-form-group>
        
        <b-form-group class="col-3" label="Data Final:" label-for="form_dataFim">
          <b-form-datepicker
            id="form_dataFim"
            v-model="form.dataFim"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-form-group>
        
        <b-form-group class="col-3" label="Hora Final:" label-for="dataFim_time">
          <b-form-timepicker v-model="form.dataFimTime" show-seconds locale="de" />
        </b-form-group>
      </div>

      <!-- Botões de Ação -->
      <div class="block text-right">
        <b-button type="reset" variant="danger">
          {{ $t('Cancel') }}
          <i class="fa fa-times"></i>
        </b-button>
        
        <b-button type="submit" variant="primary" class="ml-1">
          {{ $t('Search') }}
          <i class="fa fa-search"></i>
        </b-button>
      </div>
    </b-form>

    <!-- Controle de Movimento e Zoom -->
    <div class="form-group col-12" v-if="routeCoordinates.length > 0">
      <!-- Botão de Inicialização -->
      <div class="text-left m-1">
        <b-button
          size="sm"
          @click="init"
          variant="outline-info"
          class="rounded-circle p-1"
          v-b-tooltip.hover
          :title="$t('Search')"
        >
          <refresh-ccw-icon size="1.5x" class="custom-class"></refresh-ccw-icon>
        </b-button>
      </div>
      <!-- Detalhes da Posição do Veículo -->
      <div v-if="selectedVehicleDetails" class="vehicle-details-panel mt-2">
        <h5>Detalhes da Posição</h5>
        <div class="row">
          <div
            class="col-2"
            v-for="(value, key) in selectedVehicleDetails"
            :key="key"
            v-if="!notshow.includes(key)"
          >
            <small v-if="showdata.includes(key)">
              {{ $t(key) }}: <span>{{ format_data(value) }}</span>
            </small>
            <small v-else>
              {{ $t(key) }}: <span>{{ value }}</span>
            </small>
          </div>
        </div>
      </div>

      <!-- Controles de Animação e Download -->
      <div class="row mt-3">
        <b-form-group class="col-3 text-left" label="Intervalo de Repetição (ms)" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-1"
            class="text-left"
            v-model="playInterval"
            :options="intervalOptions"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        
        <b-form-group class="col-3" label="Loop Infinito">
          <b-form-checkbox v-model="loopPlay" switch>Repetir Continuamente</b-form-checkbox>
        </b-form-group>
        
        <div class="col-3 text-center mt-1">
          <b-button variant="primary" @click="startPlay" v-if="!isPlaying">
            <b-badge variant="success">{{ currentPointIndex }} : {{ routeCoordinates.length }}</b-badge> Play
            <i class="fa fa-play"></i>
          </b-button>
          
          <b-button variant="warning" @click="pausePlay" v-if="isPlaying">
            <b-badge variant="primary">{{ currentPointIndex }} : {{ routeCoordinates.length }}</b-badge> Pause
            <i class="fa fa-pause"></i>
          </b-button>
        </div>
        
        <div class="col-md-3 text-right mt-1">
          <b-button variant="success" @click="downloadCSV">
            Baixar CSV <i class="fa fa-download"></i>
          </b-button>
        </div>
      </div>

      <!-- Controle de Movimento -->
      <div class="d-flex align-items-center">
        <input
          type="range"
          v-model="currentPointIndex"
          :min="0"
          :max="routeCoordinates.length - 1"
          step="1"
          @input="updateVehiclePosition"
          style="flex-grow: 1; margin-left: 10px;"
        />
      </div>

      <!-- Mapa -->
      <GmapMap
        :center="center"
        :zoom="getZoom"
        style="width: 100%; height: 1000px"
        @zoom_changed="handleZoomChanged"
      >
        <gmap-cluster>
          <GmapMarker
            v-for="(coord, index) in routeCoordinates"
            :key="index"
            :position="convertToLatLng(coord)"
            :icon="smallPointIcon"
            @click="setSelectedVehicleDetails(coord)"
          />
        </gmap-cluster>

        <!-- Marcador de Início e Fim -->
        <GmapMarker :position="startPoint" :icon="startIcon" v-if="startPoint" @click="toggleInfoWindow('start')" />
        <GmapInfoWindow v-if="showInfoWindow === 'start'" :position="startPoint">
          <div>Início</div>
        </GmapInfoWindow>

        <GmapMarker :position="endPoint" :icon="endIcon" v-if="endPoint" @click="toggleInfoWindow('end')" />
        <GmapInfoWindow v-if="showInfoWindow === 'end'" :position="endPoint">
          <div>Fim</div>
        </GmapInfoWindow>

        <GmapPolyline :path="routePath" :options="polylineOptions" />
        <GmapMarker :position="vehiclePosition" :icon="vehicleIcon" v-if="vehiclePosition" @click="showVehicleDetails" />
      </GmapMap>
    </div>
    <!-- Mensagem de Localização Não Especificada -->
    <div class="form-group col-md-12 pl-2 pr-2 text-center" v-else>
      <p class="badge badge-warning">{{ $t('location not specified') }}</p>
      <CurrentLocationMap />
    </div>
  </div>
</template>

  
  <script>
  import { RefreshCcwIcon } from 'vue-feather-icons';
  import moment from 'moment';
  import CurrentLocationMap from "./CurrentLocationMap";


  export default {
    data() {
    return {
      form: {
        limit: 10000,
        dataFrist: null,
        dataFim: null,
        dataFristTime: null,
        dataFimTime: null
      },
      showInfoWindow: null,
      notshow: ['_id', 'id_rec_position', 'cause', 'pid_captura'],
      showdata: ['timestampGateway', 'timestamp', 'MsgDateTime', 'CPRDateTime'],
      zoomData: 13,
      polylineOptions: {
        strokeColor: '#ff8800',
        strokeOpacity: 0.8,
        strokeWeight: 4
      },
      startIcon: {
        url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
        label: {
          text: 'Início',
          color: '#008000',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      endIcon: {
        url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
        label: {
          text: 'Fim',
          color: '#FF0000',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      vehiclePosition: null,
      currentPointIndex: 0,
      animationInterval: null,
      smallPointIcon: null, // Inicializado posteriormente
      centerMap: { lat: 0, lng: 0 },
      selectedVehicleDetails: null,
      isPlaying: false,
      playInterval: 1000,
      loopPlay: false,
      intervalOptions: [
        { value: 125, text: '4x' },
        { value: 250, text: '3x' },
        { value: 500, text: '2x' },
        { value: 1000, text: '1x' }
      ]
    };
  },
  props: {
    url: String,
    zoom: Number,
    routeCoordinates: Array
  },
  computed: {
    getZoom() {
      return this.zoomData;
    },
    center() {
      return this.centerMap.lat && this.centerMap.lng ? this.centerMap : { lat: 0, lng: 0 };
    },
    startPoint() {
      return this.routeCoordinates[0] ? this.convertToLatLng(this.routeCoordinates[0]) : null;
    },
    endPoint() {
      return this.routeCoordinates.length
        ? this.convertToLatLng(this.routeCoordinates[this.routeCoordinates.length - 1])
        : null;
    },
    routePath() {
      return this.routeCoordinates.map(coord => this.convertToLatLng(coord));
    },
    vehicleIcon() {
      return this.url ?? null;
    }
  },
    methods: {
      toggleInfoWindow(marker) {
    // Alterna entre mostrar e ocultar a janela de informação para o marcador clicado
    this.showInfoWindow = this.showInfoWindow === marker ? null : marker;
    },
      format_data(data) {
        return moment(`${data}`).format('DD/MM/YYYY HH:mm:ss');
      },
      downloadCSV() {
      // Filtra e formata os dados
      const formattedData = this.routeCoordinates.map((coord) => {
        let newCoord = { ...coord };
        
        // Remove colunas indesejadas
        this.notshow.forEach(key => delete newCoord[key]);
        
        // Formata as datas
        this.showdata.forEach((key) => {
          if (newCoord[key]) {
            newCoord[key] = this.format_data(newCoord[key]);
          }
        });
        return newCoord;
      });

      // Converte para CSV
      const csvContent = [
        Object.keys(formattedData[0]).join(','), // Cabeçalhos
        ...formattedData.map(row => Object.values(row).join(',')) // Dados
      ].join('\n');

      // Cria um blob para download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      // Cria um link de download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'route_coordinates.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
      init() {
        this.zoomData = 16;
        this.currentPointIndex = 0;
      },
      onSubmit(event) {
        event.preventDefault();
        const searchFilter = {
          dataFrist: this.form.dataFrist ? `${this.form.dataFrist} ${this.form.dataFristTime}` : null,
          dataFim: this.form.dataFim ? `${this.form.dataFim} ${this.form.dataFimTime}` : null,
          limit: this.form.limit
        };
        this.$emit('reload', searchFilter);
      },
      onReset(event) {
        event.preventDefault();
        this.form.dataFrist = null;
        this.form.dataFristTime = null;
        this.form.dataFim = null;
        this.form.dataFimTime = null;
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
      handleZoomChanged(newZoom) {
        this.zoomData = newZoom;
      },
      startPlay() {
        this.isPlaying = true;
        this.animationInterval = setInterval(() => {
          if (this.currentPointIndex < this.routeCoordinates.length - 1) {
            this.currentPointIndex++;
          } else if (this.loopPlay) {
            this.currentPointIndex = 0; // Reinicia para o começo se o loop estiver ativo
          } else {
            this.pausePlay(); // Pausar quando chegar ao final se o loop não estiver ativo
          }
        }, this.playInterval); // Usa o intervalo selecionado
      },
      pausePlay() {
        this.isPlaying = false;
        clearInterval(this.animationInterval);
      },
      updateVehiclePosition() {
        this.vehiclePosition = this.convertToLatLng(this.routeCoordinates[this.currentPointIndex]);
        this.centerMap = this.vehiclePosition;
        this.showVehicleDetails();
      },
      convertToLatLng(coord) {
        return { lat: parseFloat(coord.lat), lng: parseFloat(coord.lon) };
      },
      showVehicleDetails() {
        this.selectedVehicleDetails = this.routeCoordinates[this.currentPointIndex];
      }
    },
    watch: {
      routeCoordinates(newCoordinates) {
        if (newCoordinates && newCoordinates.length > 0) {
          this.zoomData = newCoordinates.length > 50 ? 16 : 18;
          this.currentPointIndex = 0; // Reinicia o índice quando novas coordenadas são carregadas
          this.updateVehiclePosition();
        } else {
          this.vehiclePosition = null;
          this.zoomData = 16;
        }
      },
      currentPointIndex() {
        this.updateVehiclePosition();
      }
    },
    components: {
      RefreshCcwIcon,
      CurrentLocationMap,
    },
    setup:{
      form :{
        limit: 10000,
        dataFrist: null,
        dataFim: null,
        dataFristTime: null,
        dataFimTime: null
      }
    },
    mounted() {
      if (this.routeCoordinates && this.routeCoordinates.length > 0) {
        this.updateVehiclePosition();
      }
      
      if (window.google) {
      this.smallPointIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: '#ff7300',
        fillOpacity: 1,
        scale: 3,
        strokeColor: '#ff7300',
        strokeWeight: 1
      };
    }
    },
    beforeDestroy() {
      clearInterval(this.animationInterval);
    }
  };
  </script>
  
  <style>
  .vehicle-details-panel {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .vehicle-details-panel .row {
    display: flex;
    flex-wrap: wrap;
  }
  .vehicle-details-panel .col-md-3 {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .vehicle-details-panel .fa-info-circle {
    margin-right: 5px;
    cursor: pointer;
  }
  </style>
  