<template>
    <b-modal v-model="modalShow" size="xl" hide-footer>
        <div class="card">
            <div class="card-body">
                <div class="card text-center shadow-sm mb-4">
                    <div class="row text-muted">
                        <i class="fa fa-cogs fa-5x mt-n1 text-primary"></i>
                        <h5 class="card-title ml-1">{{ $t('Device') }}</h5>
                        <p class="ml-1">{{ config.imei }}</p>
                        <p class="ml-1">{{ config.operator }}</p>
                        <p class="ml-1">{{ config.supplier }}</p>
                    </div>
                </div>
                <div class="device-config">
                    <form @submit.prevent="saveConfig"   @reset.prevent="onReset">
                        <div class="row">

                            <!-- Permitir Modo Descanso -->
                            <div class="form-group col-4">
                            <label for="allowRestMode">Permitir Modo Descanso?</label>
                            <select
                                id="allowRestMode"
                                v-model="form.allowRestMode"
                                class="form-control"
                                required
                            >
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                            </div>
                    
                            <!-- Tempo para Acionar Descanso -->
                            <div class="form-group col-4">
                            <label for="restTime">
                                Tempo para acionar Descanso (segundos)
                            </label>
                            <input
                                type="number"
                                id="restTime"
                                v-model="form.restTime"
                                class="form-control"
                                :class="{ 'is-invalid': form.restTime < 300 || form.restTime > maxAPIValue }"
                            />
                            <div v-if="form.restTime < 300 || form.restTime > maxAPIValue" class="text-danger">
                                300-max que a API permite
                            </div>
                            </div>
                    
                            <!-- Duração do Sinistro -->
                            <div class="form-group col-4">
                            <label for="incidentDuration">Duração do Sinistro (segundos)</label>
                            <input
                                type="number"
                                id="incidentDuration"
                                v-model="form.incidentDuration"
                                class="form-control"
                                :class="{ 'is-invalid': form.incidentDuration < 300 || form.incidentDuration > maxAPIValue }"
                            />
                            <div v-if="form.incidentDuration < 300 || form.incidentDuration > maxAPIValue" class="text-danger">
                                300-Max que a API permite
                            </div>
                            </div>
                    
                            <!-- Rastreamento Normal -->
                            <div class="form-group col-4">
                            <label for="normalTracking">Rastreamento normal (segundos)</label>
                            <input
                                type="number"
                                id="normalTracking"
                                v-model="form.normalTracking"
                                class="form-control"
                                :class="{ 'is-invalid': form.normalTracking < 15 || form.normalTracking > 900 }"
                            />
                            <div v-if="form.normalTracking < 15 || form.normalTracking > 900" class="text-danger">
                                limite entre 15-900
                            </div>
                            </div>
                    
                            <!-- Rastreamento em Descanso -->
                            <div class="form-group col-4">
                            <label for="restTracking">Rastreamento em Descanso (segundos)</label>
                            <input
                                type="number"
                                id="restTracking"
                                v-model="form.restTracking"
                                class="form-control"
                                :class="{ 'is-invalid': form.restTracking < 60 || form.restTracking > 3600 }"
                            />
                            <div v-if="form.restTracking < 60 || form.restTracking > 3600" class="text-danger">
                                limite entre 60-3600
                            </div>
                            </div>
                    
                            <!-- Rastreamento em Sinistro -->
                            <div class="form-group col-4">
                            <label for="incidentTracking">Rastreamento em Sinistro (segundos)</label>
                            <input
                                type="number"
                                id="incidentTracking"
                                v-model="form.incidentTracking"
                                class="form-control"
                                :class="{ 'is-invalid': form.incidentTracking < 15 || form.incidentTracking > 900 }"
                            />
                            <div v-if="form.incidentTracking < 15 || form.incidentTracking > 900" class="text-danger">
                                limite entre 15-900
                            </div>
                            </div>
                    
                            <!-- Botões -->
                        </div>
                        <div class="block text-right">
                            <b-button type="reset" variant="danger" class="ml-1">
                            {{ $t('Cancel') }}
                            <i class="fa fa-times"></i>
                            </b-button>
                            <b-button type="submit" variant="primary" class="ml-1">
                            {{ $t('Save') }}
                            <i class="fa fa-save"></i>
                            </b-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </b-modal>
  </template>
  <script>
  export default {
    props: {
    config: {
      type: Object,
      required: true,
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
    data() {
      return {
        maxAPIValue: 10000, // Exemplo de valor máximo permitido pela API
        form: {
          allowRestMode: false,
          restTime: 300,
          incidentDuration: 900,
          normalTracking: 60,
          restTracking: 300,
          incidentTracking: 30,
        },
      };
    }, 
    watch: {
    modalShow(newVal) {
      if (newVal) {
        this.init();
      }
    },
  },
    methods: {
      saveConfig() {
        // Lógica para salvar as configurações
        console.log('Configurações salvas:', this.form);
        this.closeModal()
      },
      closeModal() {
      this.$emit("hideDeviceActivtionsConfig", false);
    },
    onSubmit() {
      if (JSON.stringify(this.form) === JSON.stringify(this.initialData())) {
        this.closeModal()
        return;
      }
      this.save();
    },
    save() {
      const api = `${this.$store.state.api}configdevices` + (this.form.id ? `/${this.form.id}` : '');
      const method = this.form.id ? "PUT" : "POST";

      axios({ method, url: api, data: this.form })
        .then(() => {
          this.$message(this.$t("Success"), this.$t("Information saved successfully"), "success");
          this.closeModal();
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    onReset() {
        this.form = {
        allowRestMode: false,
        restTime: 300,
        incidentDuration: 900,
        normalTracking: 60,
        restTracking: 300,
        incidentTracking: 30,
      };
    //   this.form = { ...this.initialData(), ...this.propsToForm() };
      this.closeModal();
    },
    init() {
    //   this.form = { ...this.initialData(), ...this.propsToForm() };
    },
    propsToForm() {
      return {
        imei: this.config.imei || '',
        operator: this.config.operator || '',
        supplier: this.config.supplier || '',
        ...this.config.configDevice,
      };
    },
    initialData() {
      return {
        imei: '',
        operator: '',
        supplier: '',
        GPRSTimeout: 0,
        MaxTimeWithNoMsg: 0,
        MsgLifeTime: 0,
        NormalTracking: 0,
        PanicExpiration: 0,
        PanicTracking: 0,
        SendTime: 0,
        SleepTime: 0,
        WakeUpSendTime: 0,
      };
    },
  
    },
  };
  </script>
  <style scoped>
  .device-config {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .form-group {
    margin-bottom: 15px;
  }
  .text-danger {
    font-size: 0.9rem;
    margin-top: 5px;
  }
  </style>