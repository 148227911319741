<template>
<div class="card text-left">

    <div class="card-body  row">

         <div class="col-md-4 " > {{ $t('maintenance_company') }} : {{ props.maintenance_company }} </div>
         <div class="col-md-4"> {{ $t('maintenance_date_start') }} : {{ formtaDateTime(props.maintenance_date_start) }} </div>
         <div class="col-md-4"> {{ $t('maintenance_date_end') }} : {{ formtaDate(props.maintenance_date_end) }} </div>
         <div class="col-md-4"> {{ $t('maintenance_next_date') }} : {{ formtaDate(props.maintenance_next_date) }} </div>
         
         <div class="col-md-4"> {{ $t('value') }} : {{ props.value }} </div>
         
         <div class="col-md-4"> {{ $t('Description') }} : {{ props.description }} </div>

    </div>
    <div class="card-footer">
        <div class="form-group col-md-12">
            <b-button v-if="props.file_url" pill variant="outline-primary" :href="props.file_url" download target="_blank">
                NFs  <i class="fa fa-download"></i>
            </b-button>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        props: {
            type: Object,
            required: true,
        }

    },
    methods:{
        formtaDate(date) {
            if(date){
                return moment(date).format('DD/MM/YYYY ')

            }
            return ''

        },
        formtaDateTime(date) {
            if(date){
                return moment(date).format('DD/MM/YYYY HH:mm:ss')

            }
            return ''

        }
    }
}
</script>
