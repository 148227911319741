var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Name"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Name'))+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle_model.name),expression:"vehicle_model.name"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"autocomplete":"new-password","type":"text","id":"Name","placeholder":""},domProps:{"value":(_vm.vehicle_model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle_model, "name", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Vehicle Brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"VehicleBrand"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Vehicle Brand'))+": ")]),_c('v-select',{staticClass:"vselect",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.vehicle_brands,"reduce":function (vehicle_brands) { return vehicle_brands.id; },"label":'name'},model:{value:(_vm.vehicle_model.vehicle_brand_id),callback:function ($$v) {_vm.$set(_vm.vehicle_model, "vehicle_brand_id", $$v)},expression:"vehicle_model.vehicle_brand_id"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Year","rules":"required|date_format:YYYY-MM-DD"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Year"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Year'))+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle_model.year),expression:"vehicle_model.year"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"autocomplete":"new-password","type":"date","max":"9999-12-31","id":"Year","placeholder":""},domProps:{"value":(_vm.vehicle_model.year)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle_model, "year", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Status"}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Status'))+": ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle_model.status),expression:"vehicle_model.status"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.vehicle_model, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]),_c('option',{attrs:{"value":"0"}},[_vm._v(" "+_vm._s(_vm.$t('Inactive'))+" ")])]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)]),_c('span',{staticClass:"required_fields"},[_c('span',{staticClass:"required"},[_vm._v("*")]),_c('strong',[_vm._v(" "+_vm._s(_vm.$t('Required Fields')))])]),_c('div',{staticClass:"block text-right"},[_c('router-link',{staticClass:"btn btn-back",attrs:{"to":"/vehicle_models"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('To Go Back'))+" ")]),_c('button',{staticClass:"btn btn-add ml-1",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" "),_c('i',{staticClass:"fa fa-save"})])],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }