<template>
    
<b-modal v-model="modalShow" size="xl"  hide-footer>

    <div class="card">

        <div class="card-body">
            <h4 class="mb-1"> {{ $t('Devices Activation')}}
                <b-badge pill :variant="props.status == 1?'success':'secondary'">{{ props.status == 1? $t('Active'): $t('Inactive')}} </b-badge>
            </h4>
            <div class="row">
                <div class="col-md-4"> {{ $t('Device Model') }} :{{ props.deviceModel }}</div>
                <div class="col-md-4"> {{ $t('Supplier') }} :{{ props.supplier }} </div>
                <div class="col-md-4"> {{ $t('Status') }} :
                    <b-badge pill :variant="props.status == 1?'success':'secondary'">{{ props.status == 1? $t('Active'): $t('Inactive')}} </b-badge>
                </div>
                <div class="col-md-4"> {{ $t('Telephone') }} :{{ props.telephone }} </div>
                <div class="col-md-4"> {{ $t('Operator') }} :{{ props.operator }} </div>
                <div class="col-md-4"> {{ $t('User Activation') }} :{{ props.userActivation }} </div>
                <div class="col-md-4"> {{ $t('User Deactivation') }} :{{ props.userDeactivation }} </div>
                <div class="col-md-4"> {{ $t('activation date') }} :{{ props.activation_date }} </div>
                <div class="col-md-4"> {{ $t('deactivation date') }} :{{ props.deactivation_date }} </div>
                <div class="col-md-4"> {{ $t('activation description') }} :{{ props.activation_description }} </div>
                <div class="col-md-4"> {{ $t('deactivation description') }} :{{ props.deactivation_description }} </div>

            </div>
        </div>
    </div>
</b-modal>
</template>

<script>
export default {
    props: {
        props: {
            type: Object,
            required: true,
        },
        modalShow: {
            type: Boolean,
            default: false,
        }

    },
    methods: {
        formtaDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')

            }
            return ''

        }
    }
}
</script>

<!-- "supplier": "Fornecedor . test",
"deviceModel": "md01",
"telephone": "(11) 5011-1111",
"operator": "test_name",
"status": 0,
"userActivation": "Ali Mohammed",
"userDeactivation": "Ali Mohammed",
"activation_date": "2024-04-30",
"deactivation_date": "2024-05-02",
"activation_description": "teste para ativar update",
"deactivation_description": "teste update" -->
