<template>
<div class="main-content">
  
    <b-card no-body>
    <b-tabs pills card >
      <b-tab title="Detalhes" active>
        <div class="row clearfix">
           
                <div class="col-12 align-content-center " :class="getStatusClass(data.level)">
                    <h4 style="color: aliceblue;"> {{ getStatusLabel(data.level) }} </h4>
                   
                </div>
        </div>
            <div class="row mt-1">
                <div class="col-md-12">
                    <VehicleData :vehicleData="data" :backBattery="backBattery" 
                    :mainBatteryDec="mainBatteryDec" :modalShow="showDeviceActivtions"  
                    :modalConfigShow="showDeviceActivtionsConfigs" @showDeviceActivations="showModalDeviceActivtions"  
                    @showDeviceActivationsConfig="showDeviceActivtionsConfig"/>
                </div>
            </div>
            <div class="mt-n4">
                <b-card-group deck>
                    <b-card no-body border-variant="warning" @click="showModalRefuleingShow"  style="cursor: pointer;">
                        <b-card-body class="pb-0">
                            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
                                <feather-icon size="21" :icon="'DollarSignIcon'" />
                            </b-avatar>
                            <div class="text-right pb-1">

                                <b-badge  variant="dark"> {{licensingExpired? formattedMoney(refuelingSomValue): '0' }} {{ $t('Paid out') }} </b-badge>

                            </div>
                            <div class="truncate mb-2">
                                <h2 class="mb-25 font-weight-bolder">
                                    {{ getLenghtRefueling}}
                                </h2>
                                <span>{{ $t('Supplies') }} </span>
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card no-body border-variant="danger" @click="showModalFineShow"  style="cursor: pointer;">
                        <b-card-body class="pb-0">
                            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
                                <feather-icon size="21" :icon="'FileTextIcon'" />
                            </b-avatar>
                            <div class="text-right pb-1">

                                <b-badge variant="warning"> {{data.fine[0]? data.fine[0]. fineType: ''}}  </b-badge>

                            </div>
                            <div class="truncate mb-2">
                                <h2 class="mb-25 font-weight-bolder">
                                    {{ getLenghtFines}}
                                </h2>
                                <span>{{ $t('Fines') }} </span>
                            </div>
                        </b-card-body>
                    </b-card>
              
                    <b-card no-body border-variant="success"  @click="showModalAutoInsuranceShow"  style="cursor: pointer;">
                        <b-card-body class="pb-0">
                            <b-avatar class="mb-1" :variant="`light-success`" size="45">
                                <feather-icon size="21" :icon="'ShieldIcon'" />
                            </b-avatar>
                            <div class="text-right pb-1">

                                <b-badge variant="primary"> {{data.autoInsurance[0]? data.autoInsurance[0]. insurance: '' }} </b-badge>

                            </div>
                            <div class="truncate mb-2">
                                <h2 class="mb-25 font-weight-bolder">
                                    {{ getLenghtAutoInsurance}}
                                </h2>
                                <span>{{ $t('Insurance') }} </span>
                            </div>
                        </b-card-body>
                    </b-card>
              
               
                    <b-card no-body border-variant="info" @click="showModalLicensingShow"  style="cursor: pointer;">
                        <b-card-body class="pb-0">
                            <b-avatar class="mb-1" :variant="`light-info`" size="45">
                                <feather-icon size="21" :icon="'CreditCardIcon'" />
                            </b-avatar>
                            <div v-if="licensingExpired" class="text-right pb-1">

                                <b-badge  variant="danger">  {{  licensingExpired ? licensingExpired +'  - '+ $t('Expired') : '...'}} </b-badge>
                            </div>
                            <div v-else class="text-right pb-1 ">

                                <b-badge  variant="warning"> {{ licensingExpired? '('+licensingExpired+') '+ $t('Expiry on'): '...'  }} </b-badge>
                            </div>

                            <div class="truncate mb-2" >
                                <h2 class="mb-25 font-weight-bolder">
                                    {{ getLenghtLicensing }}
                                </h2>
                                <span>{{ $t('Licensing') }} </span>
                            </div>
                        </b-card-body>
                    </b-card>
              
            </b-card-group>
            </div>
           
            <!-- modals  -->
            <div class="row">
                <b-modal v-model="modalFineShow" size="xl" hide-footer>
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-body">

                                <h3>{{ $t('Fines') }} : {{ getLenghtFines }}</h3>
                                <div class="col-md-12 " v-for="(item, index) in data.fine" :key="index">
                                    <fines :props="item" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal v-model="modalRefuleingShow" size="xl" hide-footer>
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-body">
                                <h3>{{ $t('Supplies') }}</h3>
                                <div class="col-md-12  " v-for="(item, index) in data.refueling" :key="index">
                                    <refueling :props="item" />

                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal v-model="modalAutoInsuranceShow" size="xl" hide-footer>
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-body">
                                <h3>{{ $t('Insurance') }}</h3>
                                <div class="col-md-12  " v-for="(item, index) in data.autoInsurance" :key="index">
                                    <AutoInsurance :props="item" />
                                </div>
                            </div>

                        </div>
                    </div>
                </b-modal>
                <b-modal v-model="modalLicensingShow" size="xl" hide-footer>
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-body">
                                <h3>{{ $t('Licensing') }}</h3>
                                <div class="col-md-12  " v-for="(item, index) in data.licensing" :key="index">
                                    <Licensing :props="item" />

                                </div>
                            </div>

                        </div>
                    </div>
                </b-modal>

            </div>
        <device-activation :modalShow="showDeviceActivtions" :props="data.deviceActivation" />
        <device-config :modalShow="showDeviceActivtionsConfigs" :config="data.deviceActivation" @hideDeviceActivtionsConfig="()=>{ showDeviceActivtionsConfigs = false;}"/>
      
        </b-tab>
        <b-tab   title="Manutenções">
              <div class="col-md-12 text-center mt-n2" >
                  <h3>Manutenções: {{ getLenghtMaintenances }}</h3>
                  <div class="text-right">
                      <router-link to="/maintenances/add" class="btn btn-primary"  >{{ $t('Add')+' '+$t('New') }}</router-link>
                  </div>
                      <div class="col-md-12 " v-for="(item, index) in data.maintenances" :key="index">
                          <Maintenances :props="item" />
                      </div>
              </div>
  
        </b-tab>
        <b-tab   :title="$t('Fines') ">
              <div class="col-md-12 text-center mt-n2" >
                  <h3>{{ $t('Fines') }} : {{ getLenghtFines }}</h3>
                  <div class="text-right">
                      <router-link to="/fines/add" class="btn btn-primary"  >{{ $t('Add')+' '+$t('Fine') }}</router-link>
                  </div>
                      <div class="col-md-12 " v-for="(item, index) in data.fine" :key="index">
                          <fines :props="item" />
                      </div>
              </div>
  
        </b-tab>
      <b-tab  title="Posição atual">
        <div class="col-md-12 text-center mt-n2">
            <MapPositionPoint  :notificationDate="positionVehicle.data"  :centerProp="center" :ray="0.2" :zoom="16"  :areaTypeId="1" :polygonCoordinates="[]"  :url="imgUrl" @reload="processPositionVehicle"  />
        </div>
           
      </b-tab>
      <b-tab  title="Posições de veiculo">
            <div class="col-md-12 text-center mt-n2" >
                <VehicleTrackingMap
                    :url="imgUrl" 
                    :zoom="17"
                    :routeCoordinates="vehiclePositions"
                    @reload="reloadData"
                    @zoomChanged="onZoomChange" 
                />
            </div>

      </b-tab>
    </b-tabs>
    <b-card-footer class="text-right">
        <router-link to="/vehicles" class="btn btn-back">
                <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
            </router-link>
        </b-card-footer>
  </b-card>
   
</div>
</template>

<script>
  // eslint-disable-next-line camelcase
import VehicleData from './components/VehicleData'
import DeviceActivation from './components/DeviceActivation';
import Fines from './components/Fines';
import Refueling from './components/Refueling';
import AutoInsurance from './components/AutoInsurance';
import Licensing from './components/Licensing';
import Maintenances from './components/Maintenances';
// import DeviceActivtionsConfig from './components/DeviceActivtionsConfig';
import DeviceConfig from './components/DeviceConfig';
import MapPositionPoint from '../../layouts/Mapa/MapPositionPoint';
import VehicleTrackingMap from '../../layouts/Mapa/VehicleTrackingMap.vue';

const LEVEL = {
    0: 'Register',
    1: 'Active',
    2: 'Inactive',
    3: 'Available',
    5: 'Off'
}

const LEVEL_CLASS = {
    0: 'badge bg-primary',
    1: 'badge bg-success',
    2: 'badge badge-secondary',
    3: 'badge badge-warning',
    5: 'badge badge-danger'
}
export default {
    data() {
        return {
            data: {
                deviceActivation: {},
                fine: [],
                refueling: [],
                autoInsurance: [],
                licensing: [],
            },
            vehiclePositions: [],
            loading: false , 
            error: null ,
            showDeviceActivtions: false,
            showDeviceActivtionsConfigs: false,
            modalLicensingShow: false,
            modalAutoInsuranceShow: false,
            modalRefuleingShow: false,
            modalFineShow: false,
            status: [{
                name: this.$t('Free'),
                id: 0
            }, {
                name: this.$t('Courtyard'),
                id: 1
            }, {
                name: this.$t('Occurrence'),
                id: 2
            }, {
                name: this.$t('Under maintenance'),
                id: 3
            }],
            errors: undefined,
            previewImage: null,
            licensingExpired: 0,
            licensingExpiryOn: 0,
            refuelingSomValue: 0,
            positionVehicle: {
                latitude: 0,
                longitude: 0,
                velocidade: 0,
                data: '',

            },
            postionDevice : {
                speed: 0,
                imei: '',
                nofixcounter: 1,
                SleepMode: 'ON',
                _id: '',
                lastfixtime: '',
                timestamp: '',
                coord: { lat: '0', lon: '0' },
                lastsampletime: '',
                backBattery:0,
                MainBatteryDec:0,
            },

            isLoading: false,
            showMapa: false,
            centerMap:{},
            has_limit: 100,
            has_dataFrist: null,
            has_dataFim: null,


        };
    },
    computed: {
        getLenghtFines() {
            return this.data?.fine?.length??0;
        },
        getLenghtMaintenances() {
            return this.data?.maintenances?.length??0;
        },
        getLenghtRefueling() {
            return this.data?.refueling?.length??0
        },
        getLenghtAutoInsurance() {
            return this.data?.autoInsurance?.length??0;
        },
        getLenghtLicensing() {
            return this.data?.licensing?.length??0;
        },
        consultApi() {
            return  this.$store.state.consultApi + this.data.license_plate;
        },
        center() {
            return this.centerMap;
        },
        imgUrl() {
            return this.data.vehicle_image_url;
        },
        backBattery() {
            return this.postionDevice.BackBattery ?Number(this.postionDevice.BackBattery):0
        },
        mainBatteryDec() {
            return this.postionDevice.MainBatteryDec ?Number(this.postionDevice.MainBatteryDec):0
        },
        imei() {
            return this.data.deviceActivation.imei?? null
        },
        limit(){
            return this.has_limit ?? 100
        },
        dataFrist() {
            if (this.has_dataFirst )
            {
                return this.has_dataFirst 
            }
          
            const data_now = new Date()
            // const oneHourInMillis = 60 * 60 * 1000;
            const oneHourInMillis = 60 * 60 * 1000;
            const data =  new Date(data_now.getTime() - oneHourInMillis);
            return this.formatDateTime(data) 
        },
        dataFim() {
            if (this.has_dataFim )
            {
                return this.has_dataFim 
            }
            const data_now = new Date()
            
            return this.formatDateTime(data_now) 
        }

       


        // getIdUrlDocVeiculo(){
        //     return this.data.vehicle.crlv;

        // },
    },
    methods: {

        formatDateTime(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        reloadData(event) {
            console.log('Recarregar dados do mapa');
            // Lógica para recarregar ou atualizar dados no mapa
            console.log('reloadData', event);

            this.fetchHistoricData(event.dataFrist , event.dataFim ,event.limit)
        },
        onZoomChange(newZoom) {
        console.log('Novo nível de zoom:', newZoom);
        },
        hideModalDetallhes(){
            this.showDeviceActivtions = false
            this.showDeviceActivtionsConfigs = false
            this.modalLicensingShow = false
            this.modalAutoInsuranceShow = false
            this.modalRefuleingShow = false
            this.modalFineShow = false

        },
        showModalDeviceActivtions() {
            this.hideModalDetallhes()
            this.showDeviceActivtions = true
        },
        showDeviceActivtionsConfig() {
            this.hideModalDetallhes()
            this.showDeviceActivtionsConfigs = true
        },
        showModalLicensingShow() {
            this.hideModalDetallhes()
            this.modalLicensingShow = true
        },
        showModalAutoInsuranceShow() {
            this.hideModalDetallhes()
            this.modalAutoInsuranceShow = true
        },
        showModalRefuleingShow() {
            this.hideModalDetallhes()
            this.modalRefuleingShow = true
        },
        showModalFineShow(){
        this.hideModalDetallhes()
        this.modalFineShow = true

        },
        formattedMoney(money) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
            }).format(money)
        },
        getStatusLabel(index) {
            if (index == 0 && this.data.deviceActivation.status == 1){
                index = 3
            }
            return this.$t(LEVEL[index]);
        },
        getStatusClass(index) {
            if (index == 0 && this.data.deviceActivation.status == 1){
                index = 3
            }

            return LEVEL_CLASS[index];
        },
        getExpiredCount(licensing) {
            this.licensingExpired = 0
            this.licensingExpiryOn = 0
            if (licensing && licensing.length) {
                licensing.forEach((item) => {
                    this.licensingExpiryOn += 1
                    if (item.situation) {
                        this.licensingExpired += 1
                        this.licensingExpiryOn -= 1
                    }
                })
            }

        },
        getRefuelingSomValue(refueling) {

            if (refueling && refueling.length) {
                refueling.forEach((item) => {
                    if (item.value) {
                        this.refuelingSomValue += Number(item.value)

                    }
                })
            }

        },
          getPositionVehicle:  function () {
            this.hideModalDetallhes()
            let api = this.$store.state.api + 'vehicles/position/' + this.data.license_plate;

                axios
                    .get(api)
                    .then((response) => {
                        this.positionVehicle  = response.data
                        this.showMapa = true

                        this.centerMap = {
                            lat: Number(this.positionVehicle.latitude),
                            lng: Number(this.positionVehicle.longitude)
                        }

                        console.log('this.centerMap', this.centerMap)

                    })
                    .catch((error) => {
                        this.$error(error);
                    });


        },
          getPositionVehicleByImei:  function () {
            this.hideModalDetallhes()
            if (!this.data.deviceActivation.imei) {
                console.log('getPositionVehicle inicio')
                return this.getPositionVehicle()
            }

            let api = this.$store.state.api + 'vehicles/imei/' + this.data.deviceActivation.imei;

                axios
                    .get(api)
                    .then((response) => {
                        console.log("getPositionVehicleByImei:",response.data)
                      
                        this.postionDevice  = response.data
                        const dateFormat = moment(`${this.postionDevice.lastsampletime}`).format('DD/MM/YYYY HH:mm:ss');
                        this.positionVehicle = {
                                    latitude: this.postionDevice.coord.lat,
                                    longitude: this.postionDevice.coord.lon,
                                    velocidade: this.postionDevice.speed,
                                    data: dateFormat,


                            }
                        this.centerMap = {
                            lat: Number(this.postionDevice.coord.lat),
                            lng: Number(this.postionDevice.coord.lon)
                        }
                        this.showMapa = true

                        console.log('this.centerMap', this.centerMap)
                        this.$message('Posição atualizado','');
                    })
                    .catch((error) => {
                        console.log("getPositionVehicleByImei error:",error)

                        this.$message('Sem posição informado','','error');
                    });

        },
        processPositionVehicle: function () {
            console.log('processPositionVehicle inicio');

            // Captura os dados necessários
            const { license_plate, deviceActivation } = this.data;
            if(deviceActivation.status != 1){
                return;

            }
            const imei = deviceActivation?.imei;
            const supplier = deviceActivation?.supplier === "Movias" ? "Movias" : "claranet";

            // Define a URL da API e os dados para o POST
            const apiUrl = `${this.$store.state.api}vehicles/processar`;
            const postData = {
                license_plate,
                imei,
                supplier,
            };

            console.log('processPositionVehicle data:', postData);

            // Envia a requisição POST para o backend Laravel
            axios
                .post(apiUrl, postData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log("processPositionVehicle:", response.data);
                })
                .catch((error) => {
                    console.error('Erro no processPositionVehicle:', error);
                })
                .finally(() => {
                    console.log('processPositionVehicle fim');
                });

            // Chama a função para obter a posição do veículo após 3 segundos
            setTimeout(() => {
                this.getPositionVehicleByImei();
            }, 3000);
            setTimeout(() => {
                console.log(" start call fetchHistoricData");
                this.fetchHistoricData(this.dataFrist, this.dataFim,  this.limit)
            }, 5000);
        },
        async fetchHistoricData(dataFrist, dataFim,  limit ) {
            console.log("fetchHistoricData inicio");
            this.loading = true;
            this.error = null;
            const apiUrl = `${this.$store.state.api}vehicles/findHistoric`;

            let postData = {
                imei: this.imei,
                dataFrist: dataFrist,
                dataFim: dataFim,
                limit: limit
                }
            console.log('fetchHistoricData postData', postData);
            axios
                .post(apiUrl, postData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log("fetchHistoricData response:", response.data);
                    this.vehiclePositions = response.data.result;

                })
                .catch((error) => {
                    console.log("fetchHistoricData error");
                    this.error = error.response ? error.response.data.message : error.message;
                    console.error('Erro fetchHistoricData ao buscar dados:', this.error);
                })
                .finally(() => {
                    console.log('fetchHistoricData fim');
                    this.loading = false;
                });

            },
        getVehicle: function (id) {
            this.hideModalDetallhes()
            let api = this.$store.state.api + 'vehicles/view/' + id;

            axios
                .get(api)
                .then((response) => {
                    this.vehicle = response.data.vehicle;
                    this.data = response.data;
                    console.log('this.data', this.data)
                    // this.getExpiredCount(this.data.licensing)
                    // this.getRefuelingSomValue(this.data.refueling)
                    // this.getPositionVehicle()
                    this.processPositionVehicle()
                })
                .catch((error) => {
                    this.$error(error);
                });
        },

    },
    mounted: function () {
        let id = this.$route.params.id;

        if (id) {
            this.getVehicle(id);
        }

      
    },
    components: {
        VehicleData,
        DeviceActivation,
        Fines,
        Refueling,
        AutoInsurance,
        Licensing,
        MapPositionPoint,
        VehicleTrackingMap,
        DeviceConfig,
        Maintenances,
    },

}
</script>
