import "./bootstrap";

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import mainStore from './store'
import App from './App.vue'
import Vuex from "vuex";
import VueInputMask from "vue-inputmask";
import i18n from '@/libs/i18n'

import vSelect from 'vue-select'

import VueToastr from "@deveodk/vue-toastr";

import ToggleButton from 'vue-js-toggle-button'

import money from 'v-money';
import * as VueGoogleMaps from 'vue2-google-maps';
// Importação do GmapCluster explicitamente
import GmapCluster from 'vue2-google-maps/src/components/cluster';

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueLoading from 'vuejs-loading-plugin'

import { VueTables, ServerTable, ClientTable, Event } from "vue-tables-2";

import "../node_modules/@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css";
import "./assets/css/main.css";
import "./assets/auxiliary/fontawesome/css/all.min.css";
import 'vue-select/dist/vue-select.css'
import '../node_modules/moment/moment'
import swal from "sweetalert"
// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, decimal, date_format } from 'vee-validate/dist/rules';
// Registrar componentes globalmente
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
// Registrar regras de validação
// extend('required', required);
// extend('decimal', decimal);
// extend('date_format', {
//   validate: (value) => {
//     const pattern = /^\d{2}\/\d{2}\/\d{4}$/;
//     if (!pattern.test(value)) {
//       return false;
//     }
//     const [day, month, year] = value.split('/').map(Number);
//     if (month < 1 || month > 12) return false;
//     if (day < 1 || day > 31) return false;
//     const daysInMonth = [31, (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
//     if (day > daysInMonth[month - 1]) return false;
//     return true;
//   },
//   message: 'The {_field_} field must be in the format DD/MM/YYYY and represent a valid date',
// });

// import core styles
require('@core/scss/core.scss')
require('@core/scss/base/pages/app-chat-list.scss')
require('@core/scss/base/pages/app-chat.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyARSbwEdX4Javrx0MNGpF6EvFUVQpH75f8',
    libraries: 'places,drawing,visualization', // Dependendo do que você precisa
  },
  installComponents: true // Permite registro automático de componentes principais

})

Vue.component('GmapCluster', GmapCluster);

Vue.use(money, { precision: 2 });

window.axios = axios
Vue.prototype.$http = window.axios;

Vue.use(BootstrapVue);
// overwrite defaults
Vue.use(VueLoading, {
  dark: false, // default false
  text: '', // default 'Loading'
  loading: false, // default false
  background: 'rgb(255,255,255, 0.68)', // set custom background
  classes: ['loadingOverwrite'] // array, object or string
})

window.moment = require('moment')
moment.locale('pt-br')

Vue.use(ToggleButton)

const translation = {
  count: "Mostrando {from} de {to} até {count} de registros",
  filter: "Pesquisar:",
  filterPlaceholder: "Pesquisar",
  limit: "Registros:",
  noResults: "Nenhum registro encontrado",
  page: "Página:",
  filterBy: "Filtrar por {column}",
  // for dropdown pagination filterBy: 'Filter by {column}',
  // Placeholder for search fields when filtering by column loading:'Loading...',
  // First request to server defaultOption:'Select {column}'
  // default option for list filters }
}


const translationI18n = {
  pt: {
      translation: {
          count: "Mostrando {from} de {to} até {count} de registros",
          filter: "Pesquisar:",
          filterPlaceholder: "Pesquisar",
          limit: "Registros:",
          noResults: "Nenhum registro encontrado",
          page: "Página:",
          filterBy: "Filtrar por {column}",
      }
  },
  en: {
      translation: {
          count: "Showing {from} to {to} of {count} records",
          filter: "Search:",
          filterPlaceholder: "Search",
          limit: "Records:",
          noResults: "No records found",
          page: "Page:",
          filterBy: "Filter by {column}",
      }
  },
  ar: {
      translation: {
          count: "عرض {from} إلى {to} من {count} سجلات",
          filter: ": بحث ",
          filterPlaceholder: "بحث",
          limit: ": سجلات ",
          noResults: "لم يتم العثور على سجلات",
          page: ": صفحة",
          filterBy: "تصفية حسب {column}",
      },
  },

  he: {
      translation: {
          count: "הצגת {from} עד {to} מתוך {count} רשומות",
          filter: ": חיפוש ",
          filterPlaceholder: "חיפוש",
          limit: ": רשומות",
          noResults: "לא נמצאו רשומות",
          page: ": דף",
          filterBy: "סינון לפי {column}",
      }
  },

  fr: {
      translation: {
          count: "Affichage de {from} à {to} sur {count} enregistrements",
          filter: "Rechercher :",
          filterPlaceholder: "Rechercher",
          limit: "Enregistrements :",
          noResults: "Aucun enregistrement trouvé",
          page: "Page :",
          filterBy: "Filtrer par {column}",
      }
  },
  es: {
      translation: {
          count: "Mostrando {from} a {to} de {count} registros",
          filter: "Buscar:",
          filterPlaceholder: "Buscar",
          limit: "Registros:",
          noResults: "No se encontraron registros",
          page: "Página:",
          filterBy: "Filtrar por {column}",
      }
  },
  de: {
      translation: {
          count: "Anzeige von {from} bis {to} von {count} Datensätzen",
          filter: "Suche:",
          filterPlaceholder: "Suchen",
          limit: "Datensätze:",
          noResults: "Keine Datensätze gefunden",
          page: "Seite:",
          filterBy: "Filtern nach {column}",
      }
  }

}

const options = {
  requestFunction: function (data) {
    // data.token = this.$store.getters.token
    return axios
      .get(this.url, {
        params: data,
      })
      .catch(
        function (e) {
          this.dispatch("error", e);
          router.push("/login");
        }.bind(this)
      );
  },
  texts: translationI18n[i18n.locale].translation,
  pagination: { chunk: 15, dropdown: false, nav: "scroll" },
  perPage: 10,
  perPageValues: [10, 25, 50, 100],
}

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ServerTable, options, false, "bootstrap4", "footerPagination");

Vue.use(VueToastr);

Vue.use(VueInputMask.default);

// Composition API
Vue.use(VueCompositionAPI)
Vue.component('v-select', vSelect)

Vue.use(Vuex);



Vue.config.productionTip = false

// export const store = new Vuex.Store(mainStore);

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    this.$store.dispatch("initialize");
  },
  i18n,
}).$mount('#app')

Vue.prototype.$message = function (title, msg, type) {
  let newMsg = "";
  if (msg instanceof Object) {
    newMsg += "<ul>";
    for (var key in msg) {
      if (msg.hasOwnProperty(key)) {
        newMsg += "<li>";
        newMsg += msg[key];
        newMsg += "</li>";
      }
    }
    newMsg += "</ul>";
  } else newMsg = msg;

  this.$toastr("add", {
    title: title,
    msg: newMsg,
    clickClose: true,
    timeout: 4000,
    position: "toast-top-right",
    type: type,
  });
};

Vue.prototype.$error = function (error) {
  let errors = error.response ? error.response.data : error;

  if (typeof errors === "object") {
    let html = "<ul>";

    $.each(errors, function (key, value) {
      html += "<li>" + value[0] + "</li>";
    });

    html += "</ul>";

    errors = html;
  }

  this.$message(null, errors, "error");
};