<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(save)">
                      <div class="form-row">
                        <div class="form-group col-md-3">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                            <label for="Name">
                              <span class="required">*</span> {{ $t('Name') }}:
                            </label>
                            <input
                              autocomplete="new-password"
                              type="text"
                              id="Name"
                              class="form-control"
                              v-model="vehicle_model.name"
                              :class="{ 'is-invalid': errors.length }"
                              placeholder=""
                            />
                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                          </div>

                          <div class="form-group col-md-3">
                            <ValidationProvider name="Vehicle Brand" rules="required" v-slot="{ errors }">
                            <label for="VehicleBrand">
                              <span class="required">*</span> {{ $t('Vehicle Brand') }}:
                            </label>
                            <v-select
                              :options="vehicle_brands"
                              :reduce="(vehicle_brands) => vehicle_brands.id"
                              :label="'name'"
                              v-model="vehicle_model.vehicle_brand_id"
                              class="vselect"
                              :class="{ 'is-invalid': errors.length }"
                            />
                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                          </div>

                          <div class="form-group col-md-3">
                            <ValidationProvider name="Year" rules="required|date_format:YYYY-MM-DD" v-slot="{ errors }">
                            <label for="Year">
                              <span class="required">*</span> {{ $t('Year') }}:
                            </label>
                            <input
                              autocomplete="new-password"
                              type="date"
                              max="9999-12-31"
                              id="Year"
                              class="form-control"
                              v-model="vehicle_model.year"
                              :class="{ 'is-invalid': errors.length }"
                              placeholder=""
                            />
                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                          </div>

                          <div class="form-group col-md-3">
                            <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                            <label for="Status">
                              <span class="required">*</span> {{ $t('Status') }}:
                            </label>
                            <select
                              class="form-control"
                              v-model="vehicle_model.status"
                              :class="{ 'is-invalid': errors.length }"
                            >
                              <option value="1"> {{ $t('Active') }} </option>
                              <option value="0"> {{ $t('Inactive') }} </option>
                            </select>
                            <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                          </ValidationProvider>
                          </div>
                      </div>

                      <span class="required_fields">
                        <span class="required">*</span>
                        <strong> {{ $t('Required Fields') }}</strong>
                      </span>
                      <div class="block text-right">
                        <router-link to="/vehicle_models" class="btn btn-back">
                          <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                        </router-link>
                        <button type="submit" class="btn btn-add ml-1">
                          {{ $t('Save') }}
                          <i class="fa fa-save"></i>
                        </button>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, date_format } from "vee-validate/dist/rules";
import axios from "axios";
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("date_format", {
  ...date_format,
  validate: (value, { format }) => {
    const regex = new RegExp(`^\\d{4}-\\d{2}-\\d{2}$`);
    return regex.test(value);
  },
  message: "The field must be in the format YYYY-MM-DD"
});

export default {
  name: "imageUpload",
  data() {
    return {
      vehicle_model: {
        name: "",
        vehicle_brand_id: "",
        year: "",
        status: "1"
      },
      vehicle_brands: [],
      errors: undefined,
    };
  },
  methods: {
    save() {
      const self = this;
      let api = self.$store.state.api + "vehicle_models";

      if (self.vehicle_model.id) {
        self.vehicle_model._method = "PUT";
        api += "/" + self.vehicle_model.id;
      }

      axios
        .post(api, self.vehicle_model)
        .then(() => {
          self.$message(
            this.$t('Success'),
            this.$t('Information saved successfully'),
            "success"
          );
          this.$router.push("/vehicle_models");
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getVehicleModel(id) {
      const self = this;
      const api = self.$store.state.api + "vehicle_models/" + id;

      axios
        .get(api)
        .then(response => {
          self.vehicle_model = response.data.data[0];
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getVehicleBrand() {
      const self = this;
      const api = self.$store.state.api + "vehicle_brands/";

      axios
        .get(api)
        .then(response => {
          self.vehicle_brands = response.data.data;
        })
        .catch(error => {
          this.$error(error);
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getVehicleBrand();

    if (id) {
      self.getVehicleModel(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
