<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(save)">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                        <ValidationProvider name="Vehicle Type" rules="required" v-slot="{ errors }">
                                                        <label for="Name">
                                                             {{ $t('Vehicle Type') }} :
                                                        </label>
                                                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                                                               v-model="vehicle_type.name" :class="{ 'is-invalid': errors.length }" placeholder="" />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                    </ValidationProvider>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <ValidationProvider name="Acronym" rules="required" v-slot="{ errors }">
                                                        <label for="Sigla">
                                                             {{ $t('Acronym') }}:
                                                        </label>
                                                        <input autocomplete="new-password" type="text" id="Sigla" class="form-control text-uppercase"
                                                               v-model="vehicle_type.sigla" :class="{ 'is-invalid': errors.length }" placeholder="" />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                    </ValidationProvider>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                                                        <label for="status">
                                                             {{ $t('Status') }}:
                                                        </label>
                                                        <select class="form-control" v-model="vehicle_type.status" :class="{ 'is-invalid': errors.length }">
                                                            <option value="1"> {{ $t('Active') }} </option>
                                                            <option value="0"> {{ $t('Inactive') }} </option>
                                                        </select>
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                    </ValidationProvider>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <ValidationProvider name="Image"  v-slot="{ errors }">
                                                        <label for="Image">
                                                             {{ $t('Image') }} :
                                                        </label>
                                                        <upload-file :fileId="vehicle_type.image" :isImage="true"
                                                                     @uploaded="(event) => { vehicle_type.image = event }" size="2rem"
                                                                     :class="{ 'is-invalid': errors.length }" />
                                                        <span class="invalid-feedback">{{ $t(errors[0]) }}</span>
                                                    </ValidationProvider>
                                                    </div>
                                            </div>
                                            <span class="required_fields">
                                                <span class="required">*</span>
                                                <strong> {{ $t('Required Fields') }}</strong>
                                            </span>
                                            <div class="block text-right">
                                                <router-link to="/vehicle_types" class="btn btn-back">
                                                    <i class="fa fa-arrow-left"></i> {{ $t('To Go Back') }}
                                                </router-link>
                                                <button type="submit" class="btn btn-add ml-1">
                                                    {{ $t('Save') }}
                                                    <i class="fa fa-save"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
    import { required } from "vee-validate/dist/rules";
    import BaseCrud from "../../layouts/Base/BaseCrud";
    import UploadFile from "../../layouts/components/UploadFile";
    
    extend("required", {
      ...required,
      message: "This field is required"
    });
    
    export default {
        name: "imageUpload",
        data() {
            return {
                vehicle_type: {
                     name: '',
                     image: '',
                     sigla: '',
                    status: "1"
                },
                errors: undefined,
                previewImage: null,
            };
        },
        methods: {
            save() {
                const self = this;
                let api = self.$store.state.api + "vehicle_types";
    
                if (self.vehicle_type.id) {
                    self.vehicle_type._method = "PUT";
                    api += "/" + self.vehicle_type.id;
                }
    
                axios
                    .post(api, self.vehicle_type)
                    .then(() => {
                        self.$message(
                           this.$t('Success'),
                            this.$t('Information saved successfully'),
                            "success"
                        );
                        this.$router.push("/vehicle_types");
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
            getVehicleType(id) {
                const self = this;
                const api = self.$store.state.api + "vehicle_types/" + id;
    
                axios
                    .get(api)
                    .then((response) => {
                        self.vehicle_type = response.data.data[0];
                    })
                    .catch((error) => {
                        this.$error(error);
                    });
            },
        },
        mounted() {
            const self = this;
            let id = self.$route.params.id;
    
            if (id) {
                self.getVehicleType(id);
            }
        },
        components: {
            BaseCrud,
            UploadFile,
            ValidationObserver,
            ValidationProvider
        },
    };
    </script>
    
    <style scoped>
    .profile_user {
        text-align: center;
    }
    
    .photo {
        display: block;
        margin-left: 5px;
        width: 18%;
        border-radius: 10px;
        max-width: 400px;
        max-height: 400px;
        object-fit: cover;
    }
    
    .btn-user:hover,
    .btn-user[aria-expanded="true"] {
        background: linear-gradient(to right, #000, #666);
        color: #fff !important;
    }
    
    .photo-title {
        color: #000;
        font-size: 18px;
        display: block;
        margin-left: 5px;
        width: 18%;
    }
    </style>
    